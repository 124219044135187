import TabsComponent from '../components/Tabs/viewer/Tabs';
import TabsController from '../components/Tabs/viewer/Tabs.controller';


const Tabs = {
  component: TabsComponent,
  controller: TabsController
};


export const components = {
  ['Tabs']: Tabs
};

